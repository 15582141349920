 /* eslint-disable */
import { AppText } from './AppText';
import { AppTextStatic } from './AppTextStatic';
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

const $axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
  headers:{
    'Accept': 'application/json'
  }
});

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeStep: 1,
    showCookies: 0,
    sectors: [],
    unions: [],
    signUpCompleted: false,
    selectedSector: null,
    language: null,
    languageLoaded: true,
    appTextStatic: new AppTextStatic(),
    appText: new AppText(),
    appTextEn: new AppText(),
    appTextCy: new AppText(),
    backgroundImage: "",
    CSRFToken: "",
    stages: [
      {
        name: 'Step 1',
        name_cy: 'Cam 1',
        active: true,
        done: false,
      },
      {
        name: 'Step 2',
        name_cy: 'Cam 2',
        active: false,
        done: false,
      },
      {
        name: 'Step 3',
        name_cy: 'Cam 3',
        active: false,
        done: false,
      },
      {
        name: 'Results',
        name_cy: 'Canlyniadau',
        active: false,
        done: false,
      },
    ],
    notFound: false,
  },
  getters: {
    getAppText: (state) => {
      return state.appText;
    },
    getAppTextStatic: (state) => {
      return state.appTextStatic;
    },
    getAppTextEn: (state) => {
      return state.appTextEn;
    },
    getAppTextCy: (state) => {
      return state.appTextCy;
    },
    getBackgroundImage: (state) => {
      return state.backgroundImage;
    },
    getCSRFToken: (state) => {
      return state.CSRFToken;
    },
    getLanguage: (state) => {

      if(state.language != null)
      {
        return state.language;
      }

      const urlParams = new URLSearchParams(window.location.search);
      const language = urlParams.get("language");

      if(language == "cy")
      {
        return language;
      }

      return "en"; // default
    }
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
    },
    setAppText(state, appText) {
      state.appText = appText;
    },
    setSectors(state, sectors) {
      state.sectors = sectors;
    },
    setUnions(state, unions) {
      state.unions = unions
    },
    setBackgroundImage(state, image) {
      state.backgroundImage = image;
    },
    setCSRFToken(state, token) {
      state.CSRFToken = token;
    },
    setSelectedSector(state, sector) {
      state.selectedSector = sector;
    },
    setActiveStep(state, step) {
      if (step < state.activeStep) {
        state.stages.forEach((el, i) => {
          if (i+1 > step-2) {
            state.stages[i].done = false
            state.stages[i].active = false
          }
        })
        if (state.stages[step-2]) {
          state.stages[step-2].active = true
        }
      } else {
        if (step === 6) {
          state.stages[2].done = true;
        }
        if (state.stages[step-3]) {
          state.stages[step-3].done = true
        }
        if (state.stages[step-2]) {
          state.stages[step-2].active = true
        }
        if (step === 6) {
          state.stages[3].done = true;
        }
      }
      state.activeStep = step;
      window.scroll(0, 0);
    },
    setNotFound(state, found) {
      state.notFound = found
    },
    setShowCookies(state, value) {
      state.showCookies = value;
    }
  },
  actions: {
    getAppText({ commit }, language)
    {
      if(!language)
      {
        language = this.getters.getLanguage;
      }

      let url = null;

      if(language == "cy")
      {
        url = "/unionfinder_content_cy"
      }
      else
      {
        url = "/unionfinder_content"
      }

      return $axios.get(url)
        .then(({ data }) => {
          const appTextData = data[0];
          const AppTextGrab = Object.assign(new AppText(), appTextData);
          return AppTextGrab;
        });

    },
    getBackgroundImages({ commit }) {
      return $axios.get('/unionfinder_background')
        .then(({ data }) => {
          const randomBackground = data[Math.floor(Math.random() * data.length)];
          const backgroundImage = process.env.VUE_APP_TUC_URL + randomBackground.background_image;
          commit('setBackgroundImage', backgroundImage);
        });
    },
    getSectors({ commit }) {
      return $axios.get('/union_finder')
        .then(({ data }) => {
          const parentCategories: any[] = [];
          data.forEach((value: { parent_target_id: string }) => {
            if(value.parent_target_id == "") {
              parentCategories.push(value);
            }
          })
          parentCategories.forEach((value) => {
            const sub = data.filter((x: { parent_target_id: any }) => x.parent_target_id == value.tid);
            if(sub) {
                value.subSectors = sub;
            }
            
          })
          
          commit('setSectors', parentCategories);
        });
    },
    getUnions({ commit }, params) {
      const language = this.state.language;
      let url = "";

      if(language == "cy")
      {
        url = "/unionfinder_detail_cy/";
      }
      else
      {
        url = "/unionfinder_detail/";
      }

      return $axios.get(url + params.sector)
      .then(({ data }) => {
        if(!data || data.length == 0) {
          commit('setUnions', []);
          commit('setNotFound', true);
        } else {
          commit('setUnions', data);
        }
        
      });
    },
    signUp({ commit }, params) {
      let formData = new FormData();

      formData.append('email', params.email);
      formData.append('sector', params.sector);
      formData.append('api_key', process.env.VUE_APP_TUC_SIGNUP_API_KEY);

      return $axios.post('/api/unionfinder/signup', formData);
    },
    getCSRFToken({commit}) {
      return $axios.get('/session/token')
      .then(({ data }) => {
        // console.log('/session/token: ');
        // console.log(data);
        commit('setCSRFToken', data);
      });
    },
    sendNotFound({ commit }, params) {
      return $axios.post('/webform_rest/submit', {
        'webform_id': 'unionfinder_not_found',
        'name': params.name,
        'email': params.email,
        'sector': params.sector,
        'message': params.message
      }, {
        headers: {
          "X-CSRF-Token": this.state.CSRFToken
        }
      }).then(() => {
      });
    }
  },
  modules: {
  }
})
