<template>
  <svg version="1.0" id="Graphics" class="branding__svg" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 722.8 127.6"
       style="enable-background:new 0 0 722.8 127.6;" xml:space="preserve">
          <g>
<g>
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="6.5979" y1="18.7478" x2="115.4247" y2="18.7478">
<stop offset="0" style="stop-color:#FFFFFF;stop-opacity:0"></stop>
  <stop offset="0.32" style="stop-color:#FFFFFF"></stop>
</linearGradient>
  <polygon class="st0"
           points="104.6,7.1 6.6,7.1 6.6,30.4 53.6,30.4 65.4,19.6 77.3,30.4 104.6,30.4 115.4,18.7 		"></polygon>
  <polygon class="st1" points="77.3,30.4 77.3,116.2 53.6,116.2 53.6,30.4 65.4,19.6 		"></polygon>
  <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="149.9562" y1="6.0396" x2="149.9562" y2="118.2954">
<stop offset="0" style="stop-color:#FFFFFF;stop-opacity:0"></stop>
    <stop offset="0.35" style="stop-color:#FFFFFF"></stop>
</linearGradient>
  <path class="st2" d="M177.9,106.6L167,95v0l0,0c-8.1,0-13.9-2.1-17.4-6.4c-3.7-4.5-4.3-10.6-4.3-13.7V6H122v68.8
			c0,10.9,3.4,21,9.6,28.6c5.6,6.8,16.3,14.9,35.4,14.9l0,0v0L177.9,106.6z"></path>
  <g class="st3">
<path class="st4" d="M200.4,3.9l-11.6,10.8v60.1c0,3.2-0.6,9.2-4.3,13.7C181,92.9,175.1,95,167,95l10.8,11.6L167,118.3
				c19.1,0,29.8-8.1,35.4-14.9c6.2-7.5,9.6-17.7,9.6-28.6V14.8L200.4,3.9z"></path>
</g>
  <g>
<defs>
<path id="SVGID_3_" d="M285.6,16.6L274.8,5l0,0v0c-14.4,0-26.5,4.7-35,13.7c-9.4,9.9-14.2,24.6-14.2,43.5
					c0,19,4.8,33.5,14.4,43.1c8.5,8.6,20.3,13,34.9,13v0L264,106.6L274.8,95v0c-12.8,0-25.9-3.9-25.9-32.8c0-12.8,2.6-22,7.8-27.4
					c4.1-4.3,10.2-6.4,18.1-6.4v0l0,0L285.6,16.6z"></path>
</defs>
    <clipPath id="SVGID_4_">
<use xlink:href="#SVGID_3_" style="overflow:visible;"></use>
</clipPath>
    <g class="st5">
<g>
<image style="overflow:visible;" width="60.9" height="113.3" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD0AAABzCAYAAAArMAr1AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABetJREFUeNrsnU2IXEUQx/tNJqKg
2XhQcpLViyCEjILn7MGzu3gWWY+CEkUPHgRzkIAgBI+CEA/x4sX1FhTcDXjVjCCCQtjdeFV3Etx1
531abWqkKat73sx0v663bxqKHvYj7/3ev7q6qnp2kqgWjqqqVmFanff3+0KhzsK0BjYAW8F5IVBx
0KichryIgIOQ1+tHVnMD7FJoyOjQALsJ0zoCRxn9hmHf97UuRUNLgg0ODbA6MF2TBDsZvRABCuwq
vNyWCOxdaYAdoLoDJXj0PAJfhumWdGBvSgOwVnezLWlsf9H1i2t3oFo0el0Dnhu6zcCLKP1lW4Hn
gsagtaZaPHozAm+2KUrbRjJj4nFLnYDRqwl8Ftex6gw0jDel5tFB3PskufUsSl9VJ2z0akTrtZMG
nUyB3hW0lkdgQ7S7YHto/goOVDk28BbYTT0nSbIX/Gpa5SrOONC1OW6TzQ2tcmdgI6q8jaccKhbw
oGHga9FDpL6JTgEj9EHXgDcaAo6a1tKMbL2h674qJj1ryLUvi0lDG6qmdCr5JGRXIynu3cR58cex
ganSoY9kRKhMlQ7d0t2SAPwfNJ4lB3dtaU2E0HnvHqg87Br0lsR20YXA17kpETpoDQuuLUrpBANZ
FfAaQ4B+VpTSDRTvQyVs9BoIYvsSoUMnJTsSoUM34kYSoVcCR+5hF91bSYTu1HpuAlotoTsSvUdS
oUMGsh+X7r2EXkIvoZfQS+h2QF+UCr2zVHoJvfBYlQo96iJ00Pw46lulIq5pkdChe1iDrq1pPS5I
hN7rmtKTs6wDFbaD8qiUdyGYgSz0ul6TGL1DQ69LhA7dy9rootL6cxI2REEHPm/SB/4l2IuiojdG
8G2PAadiZm2PSXvz3FceVa0MK9FysA+lKa1z5F1PqioGWlsBdh7UviNCafy7p+EMoC4rDMvRMrBU
CfgzRlpl7SwAWhlq2oC1PQde9ZII90YX597zXTlcma7jksCXDLy2P8A2wbt+jQ5t5OErNaArS8Ay
QTnoMSr+O9jrAH5bQhPhMwKmpsAVFjVzhJu4toY9NubTYFdidFYSS3vndk1lOVc2LTPm1FB6bMAf
6QcNin8bDRrBv8fiv6rhyiVxZVP5yTwmipt2iOA/gX0B8HdjQb8C06c1127BKJ0bCpvreAJ+TJQ2
TRc/PwP8b41CI/gvMD3BwBZkLzbVNqEzJnhRpf9GUHP+C00Hun2c78FDKJqAfhmmT5i1W5AH4Apo
uQGaEpVN6CP8+gT6EF8fGq8nD+PIWE7/3hs8kIWj9yRDu67uvzndBMkta5YmIVRZU2EaybnvZ8z1
9L0+APYgRv5TKFriY8syxxUGkoLmlu0pZcDrGgUuSSHjfZ821f4Ops8tkAVRNyN2zAQw+tr8PRPY
3Os56IXg65xwvAf2J7MH58QN6c3mDiU5yNzxcOkWGU5pVPseTG85Mi+XcaBjRmXz36PLiQMOrrQG
/xqmGxYlbO6dWSK46+cLC2jJZIJcbeAPGse7YHdqqJoxSmYkBc0Yb5kWvLwEsZmg0c3fADtwrL/M
4rK2ZCUnAYszG3gVHBrBdZb2do11nFlUNN04JfDc1kjd28u2NfP5NID/ANMHFpXzGvAF02Cwubd3
19Zjrg9LBfAbkKbqG7vkAM8t+24+xZ1dW1Xl6OqEhUbwbxD8tSn7bMpsRbnDjcuaUbxqzL0JuC78
39FV0JQkIyXrOWfWrytiewlgXqARfB+ztl2Li9PCJJ3iwq48u9ktawq4rnk/wk6qS8nCqL1dUZpr
S1W+4BPlecA6f0bdP5rtWVJQWyWWOdLT3BXdZ62nvUMjuK55XwB72gI1tlRcKVNPuwoQDVxFV5rA
n4HpebCnSDc0ZfLwutClaGgD/mGYzoOdwy+NmSSGNhG41lPRGmgD/jSqfg5zhMzRSChq1NbyoRn1
Hwc7g/eREdfm+nBcEJsZOtp/KAQ3O+lu6gegG36PgD2ETT81JUEJ0wKOOeAhnMJuZ5/szf/bz+dR
urVDf2DHvB/a8Y8AAwCqXCDKmcxYigAAAABJRU5ErkJggg==" transform="matrix(1 0 0 1 225.5629 4.9805)"></image>
</g>
</g>
</g>
  <path class="st1" d="M298.7,78.1c-1.3,4.4-3.2,7.9-5.7,10.5c-4.1,4.3-10.2,6.4-18.2,6.4v0l0,0v0l0,0L264,106.6l10.8,11.6l0,0v0
			c14.4,0,26.5-4.7,35-13.6c6.3-6.5,10.5-15.1,12.6-25.7L298.7,78.1z"></path>
  <path class="st1" d="M298.7,45.2l23.7-0.9c-2.1-10.5-6.4-19.1-12.6-25.7C301.3,9.7,289.2,5,274.8,5v0l0,0l10.8,11.6l-10.8,11.6
			l0,0v0c8,0,14.1,2.2,18.2,6.4C295.5,37.3,297.4,40.8,298.7,45.2z"></path>
</g>
            <g>
<path class="st4" d="M374.6,59.8l3.6,2c-0.8,3.1-2.5,5.5-4.9,7.2c-2.5,1.7-5.3,2.6-8.5,2.6c-4.2,0-7.6-1.4-10.2-4.1
			c-2.6-2.7-3.8-6.1-3.8-10.2c0-4.4,1.3-7.9,3.8-10.6c2.6-2.6,5.9-3.9,10-3.9c3.6,0,6.7,1,9.2,3v-2.5h3.6v9h-3.6
			c-0.8-1.8-2-3.2-3.7-4.2c-1.7-1-3.4-1.5-5.3-1.5c-2.8,0-5.1,1-6.8,3c-1.8,2-2.7,4.5-2.7,7.6c0,3.1,1,5.7,2.9,7.7
			c1.9,2,4.2,3,6.8,3c2.1,0,4.1-0.7,5.9-2.1C372.6,64.4,373.9,62.4,374.6,59.8z"></path>
              <path class="st4" d="M384.1,67.8V46.7h-3.5v-3.4h7.3V54c2.3-1.8,4.5-2.8,6.5-2.8c1.3,0,2.3,0.3,3.2,0.8c0.8,0.5,1.4,1.2,1.7,2.1
			c0.3,0.9,0.5,2.2,0.5,3.8v9.8h3.3v3.4h-7.1V59.3c0-1.5,0-2.5-0.1-2.9c-0.1-0.5-0.4-0.9-0.8-1.2c-0.4-0.3-0.9-0.5-1.5-0.5
			c-1.5,0-3.3,0.8-5.6,2.5v10.6h3.4v3.4h-10.7v-3.4H384.1z"></path>
              <path class="st4" d="M423.7,67.8v3.4h-6.7v-2.4c-1.8,1.8-3.9,2.7-6.2,2.7c-1.8,0-3.3-0.6-4.6-1.7c-1.3-1.1-2-2.6-2-4.4
			c0-1.8,0.7-3.3,2.1-4.5c1.4-1.2,3-1.8,5-1.8c1.8,0,3.6,0.5,5.3,1.6v-1.9c0-1-0.1-1.8-0.3-2.3c-0.2-0.5-0.6-1-1.3-1.4
			c-0.7-0.4-1.6-0.6-2.7-0.6c-1.9,0-3.3,0.8-4.1,2.3l-3.6-1c1.6-3,4.4-4.5,8.4-4.5c1.5,0,2.7,0.2,3.8,0.6c1.1,0.4,1.9,0.9,2.4,1.5
			c0.5,0.6,0.9,1.3,1.1,2c0.2,0.7,0.3,1.9,0.3,3.4v9H423.7z M416.7,63.9c-1.6-1.3-3.3-1.9-5-1.9c-1.1,0-1.9,0.3-2.6,0.9
			c-0.7,0.6-1.1,1.4-1.1,2.4c0,0.9,0.3,1.7,0.9,2.3c0.6,0.6,1.5,0.9,2.5,0.9c1.8,0,3.6-0.8,5.2-2.3V63.9z"></path>
              <path class="st4" d="M432.6,51.8v2.3c2.4-1.8,4.7-2.6,6.7-2.6c1.3,0,2.3,0.3,3.1,0.9c0.8,0.6,1.4,1.3,1.6,2.2
			c0.3,0.9,0.4,2.1,0.4,3.8v9.5h3.3v3.4h-7.1V58.5c0-1.5-0.2-2.4-0.7-2.9c-0.4-0.4-1-0.7-1.6-0.7c-1.7,0-3.6,0.8-5.7,2.5v10.4h3.3
			v3.4h-10.6v-3.4h3.4V55.1h-3.4v-3.4H432.6z"></path>
              <path class="st4" d="M472.5,51.8v3.4H469v14.4c0,1.9-0.1,3.4-0.3,4.3c-0.2,0.9-0.7,1.9-1.5,2.9c-0.8,1-1.9,1.8-3.5,2.5
			c-1.5,0.7-3.3,1-5.1,1c-2.9,0-5.4-0.9-7.7-2.6l2.5-2.8c1.7,1.3,3.5,2,5.5,2c1.1,0,2.1-0.2,3-0.6c0.9-0.4,1.6-0.9,2.1-1.5
			c0.5-0.6,0.8-1.2,0.9-1.7c0.1-0.5,0.2-1.5,0.2-2.7v-1.8c-0.6,0.8-1.5,1.5-2.8,2.2c-1.3,0.7-2.7,1-4.2,1c-2.8,0-5-1-6.8-2.9
			c-1.8-1.9-2.6-4.3-2.6-7.1c0-3.1,1-5.6,2.9-7.4s4.2-2.8,6.7-2.8c2.6,0,4.8,1,6.8,3.1v-2.8H472.5z M452.8,61.7c0,2,0.6,3.6,1.7,4.8
			c1.1,1.2,2.6,1.9,4.4,1.9c1.7,0,3.2-0.6,4.5-1.9c1.3-1.2,1.9-2.9,1.9-4.8c0-2-0.6-3.6-1.9-4.8S460.7,55,459,55s-3.1,0.6-4.3,1.8
			C453.5,58,452.8,59.6,452.8,61.7z"></path>
              <path class="st4" d="M476.9,67.8V55.1h-3v-3.4h6.8v16h3v3.4h-9.8v-3.4H476.9z M476.4,45.2c0-0.6,0.2-1.1,0.7-1.6
			c0.4-0.5,1-0.7,1.7-0.7c0.6,0,1.2,0.2,1.6,0.7c0.5,0.4,0.7,1,0.7,1.6s-0.2,1.2-0.7,1.6s-1,0.7-1.6,0.7c-0.6,0-1.2-0.2-1.6-0.7
			C476.6,46.3,476.4,45.8,476.4,45.2z"></path>
              <path class="st4" d="M492.5,51.8v2.3c2.4-1.8,4.7-2.6,6.7-2.6c1.3,0,2.3,0.3,3.1,0.9c0.8,0.6,1.4,1.3,1.6,2.2
			c0.3,0.9,0.4,2.1,0.4,3.8v9.5h3.3v3.4h-7.1V58.5c0-1.5-0.2-2.4-0.7-2.9c-0.4-0.4-1-0.7-1.6-0.7c-1.7,0-3.6,0.8-5.7,2.5v10.4h3.3
			v3.4h-10.6v-3.4h3.4V55.1h-3.4v-3.4H492.5z"></path>
              <path class="st4" d="M532.4,51.8v3.4H529v14.4c0,1.9-0.1,3.4-0.3,4.3c-0.2,0.9-0.7,1.9-1.5,2.9c-0.8,1-1.9,1.8-3.5,2.5
			c-1.5,0.7-3.3,1-5.1,1c-2.9,0-5.4-0.9-7.7-2.6l2.5-2.8c1.7,1.3,3.5,2,5.5,2c1.1,0,2.1-0.2,3-0.6c0.9-0.4,1.6-0.9,2.1-1.5
			c0.5-0.6,0.8-1.2,0.9-1.7c0.1-0.5,0.2-1.5,0.2-2.7v-1.8c-0.6,0.8-1.5,1.5-2.8,2.2c-1.3,0.7-2.7,1-4.2,1c-2.8,0-5-1-6.8-2.9
			c-1.8-1.9-2.6-4.3-2.6-7.1c0-3.1,1-5.6,2.9-7.4s4.2-2.8,6.7-2.8c2.6,0,4.8,1,6.8,3.1v-2.8H532.4z M512.7,61.7c0,2,0.6,3.6,1.7,4.8
			c1.1,1.2,2.6,1.9,4.4,1.9c1.7,0,3.2-0.6,4.5-1.9c1.3-1.2,1.9-2.9,1.9-4.8c0-2-0.6-3.6-1.9-4.8c-1.3-1.2-2.7-1.8-4.4-1.8
			c-1.7,0-3.1,0.6-4.3,1.8C513.4,58,512.7,59.6,512.7,61.7z"></path>
              <path class="st4" d="M543.6,55.1v-3.4h2.5v-3l3.8-3.5v6.5h3.6v3.4h-3.6v9.4c0,1.5,0.1,2.4,0.3,2.8c0.2,0.4,0.6,0.5,1.3,0.5
			c0.7,0,1.4-0.1,2.1-0.4V71c-1,0.3-1.9,0.5-2.9,0.5c-1.2,0-2.1-0.3-2.8-0.8c-0.7-0.5-1.2-1.2-1.4-1.9c-0.2-0.8-0.3-2.1-0.3-4v-9.6
			H543.6z"></path>
              <path class="st4" d="M558.6,67.8V46.7h-3.5v-3.4h7.3V54c2.3-1.8,4.5-2.8,6.5-2.8c1.3,0,2.3,0.3,3.2,0.8c0.8,0.5,1.4,1.2,1.7,2.1
			c0.3,0.9,0.5,2.2,0.5,3.8v9.8h3.3v3.4h-7.1V59.3c0-1.5,0-2.5-0.1-2.9c-0.1-0.5-0.4-0.9-0.8-1.2c-0.4-0.3-0.9-0.5-1.5-0.5
			c-1.5,0-3.3,0.8-5.6,2.5v10.6h3.4v3.4h-10.7v-3.4H558.6z"></path>
              <path class="st4" d="M599.4,62.4h-16.3c0.2,1.8,0.9,3.2,2.1,4.3c1.2,1.1,2.7,1.7,4.5,1.7c2.7,0,4.8-1.1,6.3-3.2l3.4,1.5
			c-1.1,1.7-2.5,3-4.3,3.8c-1.7,0.8-3.6,1.2-5.5,1.2c-3,0-5.5-0.9-7.6-2.8c-2.1-1.8-3.2-4.3-3.2-7.5c0-3,1-5.4,3.1-7.4
			c2.1-1.9,4.4-2.9,7.1-2.9c2.6,0,5,0.9,7.1,2.8S599.3,58.7,599.4,62.4z M595.5,59.7c-0.3-1.4-1-2.6-2.1-3.7
			c-1.1-1.1-2.6-1.7-4.2-1.7c-1.5,0-2.8,0.5-3.9,1.5c-1.1,1-1.7,2.3-2,3.9H595.5z"></path>
              <path class="st4" d="M617.9,71.5l-5.2-16.3h-2.4v-3.4h8.2v3.4h-2.5l3.7,11.7l3.7-11.7h-2.1v-3.4h9.4v3.4h-2.1l3.7,11.7l3.7-11.7
			h-2.7v-3.4h8.8v3.4h-2.8L634,71.5h-3.6l-4.4-14l-4.5,14H617.9z"></path>
              <path class="st4" d="M641.7,61.5c0-3,1-5.4,3-7.4c2-1.9,4.4-2.9,7.2-2.9c2.8,0,5.2,0.9,7.2,2.8c2,1.9,3,4.4,3,7.4c0,3-1,5.5-3,7.4
			s-4.4,2.8-7.2,2.8c-2.9,0-5.3-1-7.3-2.9C642.7,66.9,641.7,64.5,641.7,61.5z M645.7,61.4c0,1.9,0.6,3.5,1.8,4.7
			c1.2,1.2,2.7,1.8,4.4,1.8c1.7,0,3.2-0.6,4.4-1.9c1.2-1.2,1.8-2.8,1.8-4.7c0-1.9-0.6-3.5-1.8-4.7c-1.2-1.2-2.7-1.8-4.4-1.8
			c-1.8,0-3.3,0.6-4.4,1.9C646.3,58.1,645.7,59.6,645.7,61.4z"></path>
              <path class="st4" d="M668.1,67.8V55.1h-3.9v-3.4h7v4.4c0.4-1.1,1-2.1,1.8-2.8c0.8-0.7,1.6-1.2,2.4-1.5c0.8-0.2,2-0.4,3.5-0.4h1
			v3.8H679c-1.9,0-3.4,0.2-4.3,0.6S673,57,672.6,58c-0.5,1-0.7,2.9-0.7,5.5v4.3h4.2v3.4h-11.9v-3.4H668.1z"></path>
              <path class="st4" d="M683.4,67.8V46.7h-3.4v-3.4h7.3v24.5h3v3.4h-10.2v-3.4H683.4z"></path>
              <path class="st4" d="M716.2,67.8v3.4h-7.3v-2.8c-1.9,2.1-4.2,3.2-6.9,3.2c-2.5,0-4.7-0.9-6.7-2.6c-1.9-1.7-2.9-4.2-2.9-7.4
			c0-3.2,1-5.7,2.9-7.6c2-1.8,4.2-2.8,6.7-2.8c2.6,0,4.9,1,6.9,3.1v-7.7h-3.7v-3.4h7.5v24.5H716.2z M696.6,61.4
			c0,2.1,0.6,3.8,1.8,4.9c1.2,1.1,2.7,1.7,4.3,1.7c1.6,0,3.1-0.6,4.3-1.8c1.3-1.2,1.9-2.9,1.9-5c0-2.1-0.6-3.8-1.9-4.9
			c-1.3-1.1-2.7-1.6-4.3-1.6c-1.7,0-3.2,0.6-4.3,1.9C697.2,58,696.6,59.6,696.6,61.4z"></path>
              <path class="st4" d="M350.6,104.8c0-3,1-5.4,3-7.4s4.4-2.9,7.2-2.9c2.8,0,5.2,0.9,7.2,2.8c2,1.9,3,4.4,3,7.4s-1,5.5-3,7.4
			c-2,1.9-4.4,2.8-7.2,2.8c-2.9,0-5.3-1-7.3-2.9C351.5,110.3,350.6,107.8,350.6,104.8z M354.6,104.8c0,1.9,0.6,3.5,1.8,4.7
			c1.2,1.2,2.7,1.8,4.4,1.8c1.7,0,3.2-0.6,4.4-1.9c1.2-1.2,1.8-2.8,1.8-4.7c0-1.9-0.6-3.5-1.8-4.7c-1.2-1.2-2.7-1.8-4.4-1.8
			c-1.8,0-3.3,0.6-4.4,1.9C355.2,101.4,354.6,103,354.6,104.8z"></path>
              <path class="st4" d="M376.1,111.1V98.5h-2.8v-3.4h2.8v-2.8c0-2.4,0.5-4,1.5-4.9c1-0.9,2.2-1.3,3.5-1.3c2.9,0,4.6,1.5,5.1,4.5
			l-3.4,0.5c-0.1-1.2-0.6-1.9-1.5-1.9c-0.6,0-1,0.2-1.2,0.6c-0.2,0.4-0.3,1.2-0.3,2.5v2.8h3.3v3.4h-3.3v12.6h3.7v3.4h-10.3v-3.4
			H376.1z"></path>
              <path class="st4" d="M401,114.8l-5.2-16.3h-2.4v-3.4h8.2v3.4h-2.5l3.7,11.7l3.7-11.7h-2.1v-3.4h9.4v3.4h-2.1l3.7,11.7l3.7-11.7
			h-2.7v-3.4h8.8v3.4h-2.8l-5.2,16.3h-3.6l-4.4-14l-4.5,14H401z"></path>
              <path class="st4" d="M424.9,104.8c0-3,1-5.4,3-7.4s4.4-2.9,7.2-2.9c2.8,0,5.2,0.9,7.2,2.8c2,1.9,3,4.4,3,7.4s-1,5.5-3,7.4
			c-2,1.9-4.4,2.8-7.2,2.8c-2.9,0-5.3-1-7.3-2.9C425.8,110.3,424.9,107.8,424.9,104.8z M428.9,104.8c0,1.9,0.6,3.5,1.8,4.7
			c1.2,1.2,2.7,1.8,4.4,1.8c1.7,0,3.2-0.6,4.4-1.9c1.2-1.2,1.8-2.8,1.8-4.7c0-1.9-0.6-3.5-1.8-4.7c-1.2-1.2-2.7-1.8-4.4-1.8
			c-1.8,0-3.3,0.6-4.4,1.9C429.5,101.4,428.9,103,428.9,104.8z"></path>
              <path class="st4" d="M451.2,111.1V98.5h-3.9v-3.4h7v4.4c0.4-1.1,1-2.1,1.8-2.8c0.8-0.7,1.6-1.2,2.4-1.5s2-0.4,3.5-0.4h1v3.8h-0.8
			c-1.9,0-3.4,0.2-4.3,0.6s-1.7,1.1-2.1,2.1c-0.5,1-0.7,2.9-0.7,5.5v4.3h4.2v3.4h-11.9v-3.4H451.2z"></path>
              <path class="st4" d="M466.8,111.1V90h-3.5v-3.4h7.3V104l7.3-5.8h-3.2v-3.1h10.7v3.1h-3l-7.3,6.1l6.8,6.8h3.4v3.4h-5l-9.8-9.8v6.4
			h3.3v3.4h-10.6v-3.4H466.8z"></path>
              <path class="st4" d="M500.6,111.1V98.5h-2.8v-3.4h2.8v-2.8c0-2.4,0.5-4,1.5-4.9c1-0.9,2.2-1.3,3.5-1.3c2.9,0,4.6,1.5,5.1,4.5
			l-3.4,0.5c-0.1-1.2-0.6-1.9-1.5-1.9c-0.6,0-1,0.2-1.2,0.6c-0.2,0.4-0.3,1.2-0.3,2.5v2.8h3.3v3.4h-3.3v12.6h3.7v3.4h-10.3v-3.4
			H500.6z"></path>
              <path class="st4" d="M510.2,104.8c0-3,1-5.4,3-7.4c2-1.9,4.4-2.9,7.2-2.9c2.8,0,5.2,0.9,7.2,2.8c2,1.9,3,4.4,3,7.4s-1,5.5-3,7.4
			s-4.4,2.8-7.2,2.8c-2.9,0-5.3-1-7.3-2.9C511.2,110.3,510.2,107.8,510.2,104.8z M514.2,104.8c0,1.9,0.6,3.5,1.8,4.7
			c1.2,1.2,2.7,1.8,4.4,1.8c1.7,0,3.2-0.6,4.4-1.9c1.2-1.2,1.8-2.8,1.8-4.7c0-1.9-0.6-3.5-1.8-4.7c-1.2-1.2-2.7-1.8-4.4-1.8
			c-1.8,0-3.3,0.6-4.4,1.9C514.8,101.4,514.2,103,514.2,104.8z"></path>
              <path class="st4" d="M536.6,111.1V98.5h-3.9v-3.4h7v4.4c0.4-1.1,1-2.1,1.8-2.8c0.8-0.7,1.6-1.2,2.4-1.5s2-0.4,3.5-0.4h1v3.8h-0.8
			c-1.9,0-3.4,0.2-4.3,0.6s-1.7,1.1-2.1,2.1c-0.5,1-0.7,2.9-0.7,5.5v4.3h4.2v3.4h-11.9v-3.4H536.6z"></path>
              <path class="st4" d="M583.9,95.1v3.4h-3.5v14.4c0,1.9-0.1,3.4-0.3,4.3c-0.2,0.9-0.7,1.9-1.5,2.9c-0.8,1-1.9,1.8-3.5,2.5
			c-1.5,0.7-3.3,1-5.1,1c-2.9,0-5.4-0.9-7.7-2.6l2.5-2.8c1.7,1.3,3.5,2,5.5,2c1.1,0,2.1-0.2,3-0.6c0.9-0.4,1.6-0.9,2.1-1.5
			c0.5-0.6,0.8-1.2,0.9-1.7c0.1-0.5,0.2-1.5,0.2-2.7v-1.8c-0.6,0.8-1.5,1.5-2.8,2.2c-1.3,0.7-2.7,1-4.2,1c-2.8,0-5-1-6.8-2.9
			c-1.8-1.9-2.6-4.3-2.6-7.1c0-3.1,1-5.6,2.9-7.4c1.9-1.9,4.2-2.8,6.7-2.8c2.6,0,4.8,1,6.8,3.1v-2.8H583.9z M564.2,105
			c0,2,0.6,3.6,1.7,4.8c1.1,1.2,2.6,1.9,4.4,1.9c1.7,0,3.2-0.6,4.5-1.9c1.3-1.2,1.9-2.9,1.9-4.8c0-2-0.6-3.6-1.9-4.8
			c-1.3-1.2-2.7-1.8-4.4-1.8s-3.1,0.6-4.3,1.8C564.8,101.4,564.2,103,564.2,105z"></path>
              <path class="st4" d="M584.7,104.8c0-3,1-5.4,3-7.4s4.4-2.9,7.2-2.9c2.8,0,5.2,0.9,7.2,2.8s3,4.4,3,7.4s-1,5.5-3,7.4
			s-4.4,2.8-7.2,2.8c-2.9,0-5.3-1-7.3-2.9C585.7,110.3,584.7,107.8,584.7,104.8z M588.7,104.8c0,1.9,0.6,3.5,1.8,4.7
			c1.2,1.2,2.7,1.8,4.4,1.8c1.7,0,3.2-0.6,4.4-1.9c1.2-1.2,1.8-2.8,1.8-4.7c0-1.9-0.6-3.5-1.8-4.7s-2.7-1.8-4.4-1.8
			c-1.8,0-3.3,0.6-4.4,1.9C589.3,101.4,588.7,103,588.7,104.8z"></path>
              <path class="st4" d="M607.8,104.8c0-3,1-5.4,3-7.4s4.4-2.9,7.2-2.9c2.8,0,5.2,0.9,7.2,2.8c2,1.9,3,4.4,3,7.4s-1,5.5-3,7.4
			s-4.4,2.8-7.2,2.8c-2.9,0-5.3-1-7.3-2.9C608.8,110.3,607.8,107.8,607.8,104.8z M611.8,104.8c0,1.9,0.6,3.5,1.8,4.7
			c1.2,1.2,2.7,1.8,4.4,1.8c1.7,0,3.2-0.6,4.4-1.9c1.2-1.2,1.8-2.8,1.8-4.7c0-1.9-0.6-3.5-1.8-4.7c-1.2-1.2-2.7-1.8-4.4-1.8
			c-1.8,0-3.3,0.6-4.4,1.9C612.4,101.4,611.8,103,611.8,104.8z"></path>
              <path class="st4" d="M654.9,111.1v3.4h-7.3v-2.8c-1.9,2.1-4.2,3.2-6.9,3.2c-2.5,0-4.7-0.9-6.7-2.6c-1.9-1.7-2.9-4.2-2.9-7.4
			c0-3.2,1-5.7,2.9-7.6c2-1.8,4.2-2.8,6.7-2.8c2.6,0,4.9,1,6.9,3.1V90h-3.7v-3.4h7.5v24.5H654.9z M635.2,104.8
			c0,2.1,0.6,3.8,1.8,4.9c1.2,1.1,2.7,1.7,4.3,1.7c1.6,0,3.1-0.6,4.3-1.8c1.3-1.2,1.9-2.9,1.9-5c0-2.1-0.6-3.8-1.9-4.9
			c-1.3-1.1-2.7-1.6-4.3-1.6c-1.7,0-3.2,0.6-4.3,1.9C635.8,101.3,635.2,102.9,635.2,104.8z"></path>
</g>
</g>
</svg>
</template>

<script>
  export default {
    name: "Logo"
  }
</script>

<style scoped>
  .st0 {
    fill: url(#SVGID_1_);
  }

  .st1 {
    opacity: 0.5;
    fill: #FFFFFF;
  }

  .st2 {
    fill: url(#SVGID_2_);
  }

  .st3 {
    opacity: 0.5;
  }

  .st4 {
    fill: #FFFFFF;
  }

  .st5 {
    clip-path: url(#SVGID_4_);
  }
</style>
