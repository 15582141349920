<template>
  <transition
      enter-active-class="enter-active"
      leave-active-class="leave-active"

      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"

      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave"
  >
      <slot></slot>
  </transition>
</template>

<script>
  export default {
    name: "TransitionSlideToggle",
    props: {
      speed: {
        type: Number,
        default: 300,
      }
    },
    computed: {
      transitionDuration () {
        return (this.speed / 1000).toString() + 's';
      }
    },
    methods: {
      /**
       * @param {HTMLElement} element
       */
      beforeEnter(element) {
        requestAnimationFrame(() => {
          element.style.height = '0px';
          element.style.display = null;
          element.style.transition = `all ${this.transitionDuration} ease-in-out`;
        });
      },

      /**
       * @param {HTMLElement} element
       */
      enter(element) {
        requestAnimationFrame(() => {
          element.style.height = `${element.scrollHeight}px`;
        });
      },

      /**
       * @param {HTMLElement} element
       */
      afterEnter(element) {
        element.style.height = null;
        element.style.paddingTop = null;
      },

      /**
       * @param {HTMLElement} element
       */
      beforeLeave(element) {
        requestAnimationFrame(() => {
          element.style.height = `${element.scrollHeight}px`;
        });
      },

      /**
       * @param {HTMLElement} element
       */
      leave(element) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            element.style.height = '0px';
          });
        });
      },

      /**
       * @param {HTMLElement} element
       */
      afterLeave(element) {
        element.style.height = null;
      },
    },
  }
</script>
