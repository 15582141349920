<template>
  <div class="step">
    <h1>
      {{appText.step_three_title}}
    </h1>
    <p v-html="appText.step_three_text"></p>
    <div role="error" class="error" v-if="attemptedSubmit && !industry">
      <span>{{appTextStatic.industryStep.errorMessage[language]}}</span>
    </div>
    <div class="select-input" :class="{ darken: darkenLabelIndustry }">
      <label for="industry">{{appText.step_three_label}}</label>
      <br>
      <v-select :options="fullSectors" :searchable="false" v-model="industry" :clearable="false"  label="name" :placeholder="appTextStatic.industryStep.placeholder[language]" @open="darkenLabelIndustry = true" @close="(isOpen) => darkenLabelIndustry = isOpen" inputId="industry">
        <template #selected-option="{ name }">
          <span v-html="name"></span>
        </template>
        <template v-slot:option="option">
           <span class="sub_sector" v-if="option.type === 'sub'" v-html="option.name"></span>
           <span v-if="option.type !== 'sub'" v-html="option.name"></span>
        </template>
      </v-select>
    </div>
    <div style="margin: 20px 0 30px;">

      <button
          class="btn btn-text"
          @click="setNotFound(true); setActiveStep(6); setSelectedSector(null); analytics('button', 'cant_find_job')">
        {{appText.step_three_not_found}}
      </button>
    </div>

    <div role="error" class="error" v-if="error">{{appTextStatic.industryStep.findErrorMessage[language]}}</div>

    <button class="btn btn-white" style="position: relative;" @click="goNext" :disabled="disableNext">
      <span :style="{ opacity: !disableNext ? 1 : 0 }">{{appTextStatic.industryStep.nextButtonLabel[language]}}</span>
      <div class="sk-chase" v-if="disableNext">
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
      </div>
    </button>
    <back-button />
  </div>
</template>

<script>

  import { AppText } from '@/store/AppText';
  import { mapState, mapMutations } from "vuex";

  export default {
    name: "IndustryStep",
    components: {},
    data() {
      return {
        industry: null,
        job: null,
        darkenLabelIndustry: false,
        darkenLabelJob: false,
        query: null,
        attemptedSubmit: false,
        disableNext: false,
        error: false,
        appText: AppText,
        language: null,
        appTextStatic: null,
      }
    },
    created(){
      this.appTextStatic = this.$store.getters.getAppTextStatic;
      this.appText = this.$store.getters.getAppText;
      this.language = this.$store.getters.getLanguage;
    },
    computed: {
      ...mapState({
        sectors: 'sectors'
      }),
      fullSectors: function () {
        const list = [];
        
        // if there is a selected sector, store its ID
        const selectedIndustryId = (this.industry ? this.industry.tid : 0);

        this.sectors.forEach((sector) => {

          const sectorNameEn = sector.name;
          const sectorNameCy = (sector.field_welsh_name ? sector.field_welsh_name : sectorNameEn); // use English version as fallback

          const sectorName = (this.language == "cy" ? sectorNameCy : sectorNameEn);

          // if there is a selected sector, update its name based on the current language
          if(selectedIndustryId > 0 && selectedIndustryId == sector.tid)
          {
            this.industry.name = sectorName;
          }

          list.push({
            name: sectorName,
            type: 'parent',
            tid: sector.tid
          });

          if (sector.subSectors)
          {
            sector.subSectors.forEach((subSector) => {
              
              const subSectorNameEn = subSector.name;
              const subSectorNameCy = (subSector.field_welsh_name ? subSector.field_welsh_name : subSectorNameEn); // use English version as fallback

              const subSectorName = (this.language == "cy" ? subSectorNameCy : subSectorNameEn);

              // if there is a selected sector, update its name based on the current language
              if(selectedIndustryId > 0 && selectedIndustryId == subSector.tid)
              {
                this.industry.name = subSectorName;
              }

              list.push({
                name: subSectorName,
                type: 'sub',
                tid: subSector.tid
              });
            });
          }
        });

        return list;
      }
    },
    watch: {
      '$store.state.appText': function () {
        this.appText = this.$store.getters.getAppText;
      },
      '$store.state.language': function () {
        this.language = this.$store.getters.getLanguage;        
      }
    },
    methods: {
      goNext() {
        const cookieAgreed = this.$cookies.get('cookie-agreed');
        const cookieAnalytics = this.$cookies.get('cookie-on-analy');
        if(cookieAgreed != null && cookieAgreed != 0) {
          if(cookieAnalytics != null && cookieAnalytics == 1) {
            window.fbq('track', 'CompleteRegistration');
          }
        }
        this.analytics('industry', 'next')
        this.setNotFound(false);
        this.disableNext = true;

        const sector = this.industry ? this.industry.tid : null;

        this.$store.state.selectedSector = sector;

        if (sector) {
          this.analytics('industry_select', this.industry.name);
          this.analytics('search_by', 'industry');

          this.$store.dispatch('getUnions', {
            sector: sector
          }).then(() => {
            this.analytics('industry', 'found');
            this.disableNext = false;
            this.setActiveStep(5) // 5 = goes to Signup form - 6 = skip Signup form
          }).catch(() => {
            this.analytics('industry', 'error');
            this.error = true;
            this.disableNext = false;
          });
        } else {
          this.analytics('industry', 'error');
          this.disableNext = false;
          this.attemptedSubmit = true;
        }
      },
      setQuery (query) {
        this.query = query;
      },
      ...mapMutations({
        setActiveStep: 'setActiveStep',
        setNotFound: 'setNotFound',
        setSelectedSector: 'setSelectedSector',
      })
    }
  }
</script>

<style scoped lang="scss">
  .error {
    width: 100%;
  }

  .sub_sector{
    padding-left: 20px;
    display:block;
    opacity:.8;
  }

  .step {
    width: 100%;
    max-width: 460px;
    background-color: #652E55;
    border-radius: 9px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 767px) {
      max-width: 100%;
    }

    //IE11 Flexbox fix
    //https://github.com/philipwalton/flexbugs#flexbug-2
    > * {
      max-width: 100%;
    }
  }

  h1 {
    font-size: 30px;
    text-align: center;
    margin-top: 0;
    @media (max-width: 767px){
      font-size: 24px;
    }
  }

  p {
    font-size: 19px;
    line-height: 24px;
    text-align: center;
  }

  .message {
    overflow: hidden;

    .inner_message {
      font-size: 16px;
      line-height: 1.4;
      text-align: left;
      padding-top: 10px;
    }
  }

.sk-chase {
  width: 30px;
  height: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #652E55;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  } 100%, 0% {
    transform: scale(1.0);
  }
}
</style>
